.Footer-page {
  .Footer-menu-card {
    display: flex;
    color: #eee;
    justify-content: space-between;
    padding: 20px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block;
      text-align: center;
    }
  }
  .Footer-menu-subcard{
    display: flex;
    align-items: center;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block;
    }
  }
  .Footer-menu-card1 {
    background: #eeeeee4a;
    white-space: normal;
    overflow: auto;
    padding: 40px 0px 0px 0px;
    border-top: 1px solid #eee;
  }
  .Footer-menu-card01 {
    background: #3d2b93;
  }
  .Footer-menu-card01 {
    display: flex;
    align-items: center;
    color: #eee;
    justify-content: space-around;
    padding: 20px;
    white-space: normal;
    overflow: auto;
  }
  .Footer-menu-item {
    font-size: 16px;
    font-weight: 500;
    h5 {
      font-size: 1rem;
    }
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding-bottom: 20px;
    }
  }
  .Footer-menu-SubItem {
    padding: 5px 5px 5px 5px;
    font-weight: 300;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: inline-block;
      padding: 10px;
    }
    img {
      padding-bottom: 20px;
    }
    a {
      color: #363535;
    }
    a:hover {
      color: #222323;
    }
  }
  .Footer-menu-socia-SubItem {
    padding: 5px 5px 5px 5px;
    font-weight: 300;
    img {
      padding-bottom: 20px;
    }
    a {
      color: #444;
      display: flex;
      align-items: center;
      font-size: 28px;
      padding-right: 15px;
    }
  }
  .Footer-menu-social {
    display: flex;
    align-items: center;
    padding-top: 15px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      justify-content: center;
    }
  }
  .Footer-cp {
    text-align: right;
    color: #8e8e8e;
    font-size: 12px;
  }
}
.Footer-menu-bottom {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    display: block !important;
  }
  display: flex;
  width: 100%;
  .Footer-menu-SubItem {
    font-size: 14px;
    padding-right: 20px;
    color: #808385 !important;
    font-weight: 400 !important;
  }
  .Footer-menu-SubItem:last-child {
    padding-right: 0 !important;
  }
}

.footer-app-icons {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    justify-content: center;
  }
}
