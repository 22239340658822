.Tutorial-page {
  .box-content-t-nav{
    display: flex;
    align-items: center;
    svg{
      font-size: 15px;
      margin: 0 5px;
    }
  }
  .search-filter-cards {
    white-space: nowrap !important;
    overflow: auto !important;
    button {
      border: 1px solid #d8dbdd;
      color: #000000b3;
      text-transform: capitalize;
      font-size: 1rem;
      border-radius: 100px;
      margin: 7;
      width: auto !important;
      font-size: 12px;
    }
  }
  .search-page-nav {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block !important;
    }
  }
  .tutorail-s-c {
    width: 175px;
    height: 98px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      width: 100%;
      height: 150px;
      margin-right: 0px;
    }
  }
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    .m-hide {
      display: none !important;
    }
  }
  .rhap_time {
    color: #fcfcfc !important;
  }

 
  
  .img-list-card {
    min-height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid #eee;
    margin-bottom: 7px;
    padding: 0px;
    border-radius: 7px;
  }
  .img-list-img-t {
    width: 100%;
    height: 360px;
    object-fit: contain;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    z-index: 2;
    border-radius: 7px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      height: auto !important;
    }
  }
  .Book-card-item {
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
    p {
      margin: 0;
      font-size: 14px;
    }
  }
  .Book-card-item1 {
    padding-bottom: 20px;

    p {
      margin: 0;
    }
  }
  .Book-key-list-button {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding-top: 20px;
    }
  }

  .features {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      overflow: auto;
      white-space: nowrap;
    }

    .duration {
      padding-right: 20px;
      font-size: 15px;
      display: flex;
      align-items: center;
    }
    .keys {
      padding-right: 20px;
      display: flex;
      align-items: center;
      font-size: 15px;
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        font-size: 12px;
      }
    }
    .keys-ar {
      padding-left: 20px;
      display: flex;
      align-items: center;
      font-size: 15px;
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        font-size: 12px;
      }
    }
    .audio {
      font-size: 15px;
      display: flex;
      align-items: center;
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        font-size: 12px;
      }
    }
  }
  .Book-key-list {
    display: flex;
    align-items: center;
    padding: 20px 0px 20px 0px;
    justify-content: space-between;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 10px 0px 10px 0px !important;
      position: fixed !important;
      bottom: 0 !important;
      z-index: 111 !important;
      background: #fff !important;
      right: 10px !important;
      left: 10px !important;
      justify-content: center !important;
      overflow: auto;
      white-space: nowrap;
    }
  }
  .book-subtitle {
    padding: 20px 0px 20px 0px;
  }
  .book-title {
    font-size: 1.3rem;
    font-weight: 600;
  }
  .Book-details {
    padding: 30px 0px 20px 0px;
    border-bottom: 1px solid #21294517;
    margin-bottom: 25px;
    .Book-details-heading {
      font-weight: 600;
    }
  }
  .lit_button {
    background: #222945;
    border: 1px solid #222945;
    width: auto !important;
    justify-content: center;
    border-radius: 0;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      width: 100% !important;
    }
  }
  .book-button-width {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      width: 100% !important;
    }
  }
  .lit_button:hover {
    background: #222945de !important;
    border: 1px solid #222945de !important;
  }
  .lit_button:focus {
    background: #222945de !important;
    border: 1px solid #222945de !important;
  }
  .lit_button:active {
    background: #222945de !important;
    border: 1px solid #222945de !important;
  }
  .w100 {
    width: 100%;
  }
  .tac {
    text-align: center;
  }
  .jfc {
    justify-content: center;
  }

  .read-button:hover {
    .product-page-remove {
      display: block !important;
    }
    .product-page-saved {
      display: none !important;
    }
  }
  .product-page-remove {
    display: none;
  }

  .Book-summary-details-section {
    padding-top: 30px;
  }
  .Book-audio-player {
    .rhap_additional-controls {
      display: none !important;
    }
    .rhap_volume-controls {
      display: none !important;
    }
    .rhap_main {
      display: flex !important;
      flex-direction: row-reverse !important;
      align-items: center !important;
      border-radius: 8px;
    }
    .rhap_stacked .rhap_controls-section {
      margin-top: 0px !important;
      flex: none !important;
    }
    .rhap_container {
      border: none !important;
      box-shadow: none !important;
    }

    .Book-audio-player {
      border: none !important;
      box-shadow: none !important;
      padding: 40px 0px 40px 0px;
    }

    .rhap_play-pause-button {
      font-size: 50px !important;
      width: 50px !important;
      height: 50px !important;
      color: #fcfcfc;
    }
  }

  .Book-keys-list {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block !important;
    }
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    margin-bottom: 10px !important;
    display: flex;
    align-items: flex-start;
    .ar {
      padding-left: 10px;
    }
    .en {
      padding-right: 10px;
    }
  }
  .Book-loginSignup {
    width: 100%;
  }
  .Book-loginSignup-form {
    border: 1px solid #eee;
    padding: 20px;
    border-radius: 6px;
    width: 470px;
    max-width: 100%;
    background: #fff;
  }
  .Book-loginSignup_rtl {
    padding-left: 40px;
  }
  .Book-loginSignup_ltr {
    padding-right: 40px;
  }
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #fff0 !important;
}

.ant-dropdown-menu-item:hover {
  background-color: #eee !important;
}

.tutorial-play-button {
  svg {
    width: 1.2rem !important;
    height: 1.2rem !important;
  }
}
.tutorial-back-button {
  svg {
    width: 1.2rem !important;
    height: 1.2rem !important;
  }
}
.tutorial-next-button {
  svg {
    width: 1.2rem !important;
    height: 1.2rem !important;
  }
}
.tutorial-full-sacreen-button {
  svg {
    width: 1.2rem !important;
    height: 1.2rem !important;
  }
}

 

.player-section {
  background-color: #000000;
}

.tutorial-llt {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #363535;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  position: relative;
  width: 100%;
  margin: 0 10px;
}

.summaries-title-tutorials {
  font-weight: 500;
  font-size: 1.2rem;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  justify-content: left;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 64px;
  min-height: 1px;
  text-transform: capitalize;
}
.summaries-tutorials-section {
  position: relative;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    padding-top: 5px;
  }
}

.bouncing-loader {
  display: inline-flex;
  justify-content: center;
}

.bouncing-loader > div {
  width: 6px;
  height: 6px;
  margin: 1px 3px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}
@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-0px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

 
.tutorials-player-card {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    padding: 0 !important;
  }
}
.tutorial-body {
  padding-top: 10px;
  h4 {
    font-size: 1rem;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 1rem;
    }
  }
  .tutorial-details {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: none !important;
    }
  }
}

.plyr__progress input[type="range"] {
  color: #000000 !important;
}

.plyr__volume input[type="range"] {
  color: #000000 !important;
}


.tutorials-player-section {
  position: relative;
}

 

.plyr__menu__container:after {
  border: none !important;
  position: relative !important;
}
.plyr__menu__container {
  border-radius: 0 !important;
  margin: 0 !important;
  top: -6px !important;
  right: 0 !important;
  background: #fff0 !important;
  box-shadow: none !important;
  span {
    color: #ff4646;
    font-size: 10px;
    font-weight: 700;
  }
}

.controller-dfsb-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
}

.ui-video-seek-slider .thumb {
  opacity: 0 !important;
  top: 3.5px !important;
  width: 11px !important;
  height: 11px !important;
}

.ui-video-seek-slider:hover .thumb {
  opacity: 1 !important;
}

.controller-dfsb-bottom {
  .mv-direction {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: flex !important;
      flex-direction: column-reverse !important;
    }
  }
  .ui-video-seek-slider {
    margin-bottom: 18px;
    .track .main .inner-seek-block {
      height: 4px;
    }
    .track .main:before {
      height: 4px;
    }
    .track .main:hover .inner-seek-block {
      height: 5px;
    }
    .track .main:hover:before {
      height: 5px;
      .track .main .inner-seek-block {
        height: 5px;
      }
    }
    .track .main .connect {
      background-color: #8ebdfcd4 !important;
    }
    .thumb {
      .handler {
        background-color: #8ebdfcd4 !important;
      }
    }
  }

  .Mui-disabled {
    color: #b0acac !important;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px 5px 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.controller-dfc {
  display: flex;
  justify-content: center;
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  align-items: center;
}

.controller-valume-slider {
  width: 68px !important;
  .MuiSlider-track {
    color: #fcfcfc;
    height: 4px;
  }
  .MuiSlider-rail {
    color: #eee;
    height: 4px;
  }
  .MuiSlider-thumbColorPrimary {
    color: #fff;
  }
  .MuiSlider-thumb::after {
    position: relative !important;
    border-radius: 0 !important;
  }
}
.controller-bottom-icons {
  svg {
    width: 2rem;
    height: 2rem;
  }
}

.controller-bottom-icons-center {
  svg {
    width: 4rem;
    height: 4rem;
  }
}

.controller-valume-slider {
  display: none !important;
  .MuiSlider-thumb {
    margin-top: -3.5px !important;
  }
}

.controller-volume-slider-section:hover .controller-valume-slider {
  display: block !important;
  animation: fade_in_show 0.3s;
}
@keyframes fade_in_show {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.player-wrapper:hover .player-overlay-tutorial {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  background-color: #00000026;
}

.player-wrapper:hover .player-container-controller {
  -webkit-animation: cssAnimationa 1s forwards;
  animation: cssAnimationa 1s forwards;
}
.player-container-controller {
  -webkit-animation: cssAnimation 3s forwards;
  animation: cssAnimation 3s forwards;
}
@keyframes cssAnimation {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes cssAnimation {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes cssAnimationa {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes cssAnimationa {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.controller-pro-slider {
  .MuiSlider-thumb:focus {
    transition: none !important;
  }
  .MuiSlider-rail:focus {
    transition: none !important;
  }
  .MuiSlider-track:focus {
    transition: none !important;
  }
}
.controller-pro-slider {
  .MuiSlider-thumb:active {
    transition: none !important;
  }
  .MuiSlider-rail:active {
    transition: none !important;
  }
  .MuiSlider-track:active {
    transition: none !important;
  }
}

.controller-pro-slider {
  .MuiSlider-thumb {
    height: 13px;
    width: 13px;
    opacity: 0;
    box-shadow: none !important;
    background-color: #fff;
    top: 1px;
  }
  .MuiSlider-rail {
    height: 5px;
  }
  .MuiSlider-track {
    height: 5px;
    transition: all 1s ease-out !important;
  }
}

.controller-pro-slider:hover {
  .MuiSlider-thumb {
    opacity: 1;
    transition: all 1s ease-out !important;
  }

  .MuiSlider-rail {
    transition: none !important;
  }
  .MuiSlider-track {
    transition: none !important;
  }
}

.controller-quality-list {
  label {
    margin: 0 !important;
    .MuiFormControlLabel-label {
      font-size: 14px !important;
    }
  }
  padding: 5px 12px 5px 5px !important;
  .MuiRadio-root {
    padding: 5px !important;
  }
  svg {
    width: 1.3rem;
    height: 1.3rem;
  }
  .MuiFormControlLabel-label {
    font-size: 15px;
  }
}
.cq-dropdown {
  bottom: 10px !important;
  transform: none !important;
  inset: auto !important;
  bottom: 56px !important;
  right: 0 !important;
  .MuiPaper-root {
    background: #222529d4;
  }
  .pq-list-card {
    height: 200px;
    overflow: auto;
  }
  ul {
    width: 224px;
    max-width: 100%;
    li {
      label {
        color: #fcfcfc !important;
        span {
          color: #fcfcfc !important;
        }
      }
    }
  }
}
.CircularProgress-tutorial {
  position: absolute;
  display: flex;
  align-items: center;
  right: 0;
  left: 0;
  height: 100%;
  top: 0;
  justify-content: center;
}

.mobile-setting-modal-items {
  background-color: #222529d4 !important;
}
