.Account-page {
  .df {
    display: flex;
  }
  .jcsb {
    justify-content: space-between;
  }
  .ai {
    align-items: center;
  }
  // .section-card {
  //   background: #f7f7f7;
  //   padding: 20px;
  //   border: 1px solid #eee;
  // }
  .row-card {
    margin-bottom: 1px;
    border-bottom: 1px solid #eee;
    padding: 10px;
    display: flex;
    align-items: center;
  }

  .button-heights {
    height: 50px;
    background-color: #222945;
    border: none;
    font-size: 1rem;
    padding: 0px 20px 0px 20px;
  }
  .button-heights:hover {
    height: 50px;
    background-color: #222945;
    border: none;
  }
  .button-heights:focus {
    height: 50px;
    background-color: #222945;
    border: none;
  }
}
.refund-modal {
  display: flex !important;
  align-items: center;
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 540px;
    width: 100%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
}

.account-side-menu {
  .MuiDrawer-paperAnchorLeft {
    position: relative;
    z-index: 1;
    display: flex;
    background-color: #fff0 !important;
    border: none !important;
  }
}
.Profile-page-section {
  display: flex;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    display: block !important;
  }
}

.account-page-content-section {
  padding: 20px !important;
  border: 1px solid #eee;
  border-radius: 10px;
  margin: 0 10px;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    padding: 0px 0px 0px 0px !important;
  }
}

.Category-item {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    padding: 0px !important;
  }
  .account-page-content-section {
    margin: 0 10px !important;
  }
}
.cardList__margin{
  @media only screen and (min-width: 0px) and (max-width: 992px) {
  .col-md-12{
    padding: 0px !important;
  }
  .account-page-content-section {
    margin: 0 !important;
  }
}
}