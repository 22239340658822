.Summary-page {
  .summary-section-card {
    display: flex;
    justify-content: center;
    .summary-text-style {
      // width: 765px;
      // max-width: 100%;
      width: 100%;
      border: 1px solid #eee;
      padding: 0px;
      background: #eee;
      height: 100vh;
      position: relative;
    }
  }

  @media only screen and (min-width: 0px) and (max-width: 992px) {
    .mobile-hide-summary-page {
      display: none !important;
    }

    .summary-section-card {
      padding: 0 !important;
    }
  }

  .summary-page-subHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    background: #eeeeee;
    padding: 10px 40px 10px 40px;
    overflow: auto;
    white-space: nowrap;
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
    top: 60px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      top: 40px !important;
    }
  }
  .Summary_ContentIcon {
    background: #000;
    border-radius: 100px;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    color: #fff;
  }
  .summary-language_select {
    display: "flex";
    align-items: "center";
    font-size: "18px";
    color: "#000";
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: "12px";
    }
  }
  .ProductDescrition_rtl {
    div {
      text-align: right;
    }
    p {
      text-align: right;
    }
    strong,
    em,
    span {
      text-align: right;
      padding-left: 10px;
    }
  }
  .ProductDescrition_ltr {
    div {
      text-align: left;
    }
    p {
      text-align: left;
    }
    strong,
    em,
    span {
      text-align: left;
    }
  }
  .dFlex {
    display: flex;
    align-items: center;
  }

  .font-class-ar {
    font-family: "GessTwoMedium, sans-serif";
  }
  .font-class-urdu {
    font-family: "AlQalam Shakir";
  }
  .font-class-default {
    font-family: "Poppins, sans-serif";
  }

  .HomePage_static_description {
    padding: 20px;
    background: #eeeeee;
    margin-bottom: 80px;

    div,
    p,
    em,
    span,
    ul,
    li {
      font-size: 1rem !important;
      font-weight: 400 !important;
      line-height: 1.5 !important;
      color: #212529 !important;
      background: #fff0 !important;
      text-transform: none;
      text-decoration: none;
      margin: 0 !important;
      padding-bottom: 10px !important;
      white-space: normal !important;
      text-align: start !important;
      padding: 0;
      font-style: normal;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    strong {
      font-size: 1rem !important;
      font-weight: 700 !important;
      line-height: 1.5 !important;
      color: #212529 !important;
      background: #fff0 !important;
      text-transform: none;
      text-decoration: none;
      margin: 0 !important;
      padding-bottom: 10px !important;
      white-space: normal !important;
      text-align: start !important;
      padding: 0;
      font-style: normal;
    }
  }

  .rhap_repeat-button {
    display: none !important;
  }
  .summary-page-key {
    display: flex;
    align-items: center;
    .margin {
      margin: 0 4px;
    }
  }
  .row-reverse-ar {
    .rhap_main {
      .rhap_controls-section {
        .rhap_main-controls {
          flex-direction: row-reverse !important;
        }

        .rhap_volume-container {
          flex-direction: row-reverse !important;
        }
      }
      .rhap_progress-section {
        flex-direction: row-reverse !important;
        .rhap_progress-container {
          .rhap_progress-bar {
            display: flex !important;
            flex-direction: row-reverse !important;
          }
        }
      }
    }
  }
  .rhap_footer-bottom {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 80px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      height: 70px;
    }
    left: 200px;
    right: 200px;
    .center-i {
      background: #d8d8d8;
    }
    span {
      font-size: 1.1rem;
      font-weight: 500;
      color: #1b477d;
      cursor: pointer;
    }
    .border {
      border-right: 1px solid #b2b2b2 !important;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .rhap_footer-bottom-ar {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 80px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      height: 70px;
    }
    left: 200px;
    right: 200px;
    .center-i {
      background: #d8d8d8;
    }
    span {
      font-size: 1.1rem;
      font-weight: 500;
      color: #1b477d;
      cursor: pointer;
    }
    .border {
      border-right: 1px solid #b2b2b2 !important;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  padding-bottom: 100px;
  .book-details-page-card {
    background: #fcfcfc;
    padding: 25px;
  }
  .Book-card-item {
    padding-bottom: 20px;
    p {
      margin: 0;
      font-size: 14px;
    }
  }
  .Book-card-item1 {
    padding-bottom: 20px;
  }
  .Book-key-list {
    display: flex;
    align-items: center;
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    padding: 10px 0px 10px 0px;
    margin-top: 20px;

    .duration {
      padding-right: 20px;
      font-weight: 600;
    }
    .keys {
      padding-right: 20px;
      font-weight: 600;
    }
    .audio {
      font-weight: 600;
    }
  }
  .book-subtitle {
    padding: 30px 0px 10px 0px;
  }
  .book-title {
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.5;
    color: #363535;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    position: relative;
    width: 100%;
    margin-bottom: 0.5rem;
    text-transform: capitalize;
    padding: 0px 10px 0px 10px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 15px !important;
    }
  }
  .Book-details {
    padding: 30px 0px 20px 0px;
    .Book-details-heading {
      font-weight: 600;
    }
  }

  .Book-summary-details-section {
    .rhap_container {
      background-color: #fff0 !important;
      border: none !important;
      box-shadow: none !important;
      padding: 0 !important;
    }
  }

  .rhap_additional-controls {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: none !important;
    }
  }

  .Book-summary-side {
    padding-right: 40px !important;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding-right: 0px !important;
    }
    .rhap_main {
      position: fixed;
      right: 0;
      left: 0;
      bottom: 0;
      background: #000;
      padding: 20px 30px 10px 30px;
    }
  }
  .Book-keys-list {
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
    margin-bottom: 15px !important;
  }
  .Book-loginSignup {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 999;
    height: 100%;
    top: 0;
    align-items: center;
  }
  .Book-loginSignup-form {
    border: 1px solid #eee;
    padding: 20px;
    border-radius: 6px;
    width: 470px;
    max-width: 100%;
    background: #fff;
  }
  .rhap_main-controls-button {
    color: #fcfcfc !important;
  }
  .rhap_time {
    color: #fcfcfc !important;
  }
  .rhap_progress-indicator {
    background: #fcfcfc !important;
  }
  .rhap_progress-filled {
    background-color: #fcfcfc;
  }
  .rhap_volume-button {
    color: #fcfcfc !important;
  }
}

.voice-controls {
  background-color: #fff0 !important;
  padding: 0 !important;
  .plyr__time_voice + .plyr__time_voice:before {
    margin: 0 6px !important;
  }
  .plyr__control {
    color: #000 !important;
    .icon--pressed {
      width: 2rem !important;
      height: 2rem !important;
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        width: 1.3rem !important;
        height: 1.3rem !important;
      }
    }
    .icon--not-pressed {
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        width: 1.3rem !important;
        height: 1.3rem !important;
      }
      width: 2rem !important;
      height: 2rem !important;
    }
  }
}

.summary-lit-player-sticky {
  // position: -webkit-sticky !important;
  // position: sticky !important;
  position: fixed !important;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 111;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 80px;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    height: 70px;
  }
  display: flex;
  width: 100%;
  background: #e2e2e4;
  .plyr--audio {
    width: 100%;
  }
}

.summaries-lit-player {
  // border-top-left-radius: 15px !important;
  // border-top-right-radius: 15px !important;
  .plyr__progress {
    input {
      height: auto !important;
    }
  }

  .plyr__time + .plyr__time:before {
    display: none !important;
  }

  @media only screen and (min-width: 0px) and (max-width: 992px) {
    .plyr__volume {
      display: none !important;
    }
  }
  .plyr__progress input[type="range"] {
    color: #000 !important;
  }
  .plyr__volume input[type="range"] {
    color: #000 !important;
  }

  button[data-plyr="fast-forward"] {
    svg {
      width: 2rem !important;
      height: 2rem !important;
    }
  }

  button[data-plyr="rewind"] {
    svg {
      width: 2rem !important;
      height: 2rem !important;
    }
  }

  button[data-plyr="Play"] {
    svg {
      width: 2rem !important;
      height: 2rem !important;
    }
  }

  button:hover {
    background-color: #fff0 !important;
  }
}

.seek-i {
  svg {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      width: 1.5rem;
      height: 1.5rem;
    }
    color: #000;
    width: 1.8rem;
    height: 1.8rem;
  }
}

.custom-button-arrows {
  svg {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      width: 2rem;
      height: 2rem;
    }
    color: #000;
    width: 2.5rem;
    height: 2.5rem;
  }
}

.summary-lit-page {
  border: #e6ebf1;
  border-radius: 0px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.summary-side-menu .MuiDrawer-paperAnchorLeft {
  background-color: #fff0 !important;
  border: none !important;
  display: flex;
  position: relative;
  z-index: 1;
  width: 100% !important;
}
.summary-side-menu {
  width: 100% !important;
}
.summary-page-mobile-menu-icon {
  display: flex !important;
  align-items: center;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    display: flex !important;
    min-height: auto !important;
    padding: 0 !important;
  }
}
.summary-lit-list-card {
  align-items: flex-start !important;
  padding: 10px;
  text-align: start !important;
  text-transform: capitalize !important;
  line-height: 1.3 !important;
  display: flex;
  justify-content: flex-start !important;
  width: 100% !important;
  color: #000000bf;
  margin-bottom: 5px !important;
}
.summary-lit-list-m {
  display: block !important;

  .MuiDrawer-paperAnchorLeft {
    width: 320px !important;
    max-width: 100% !important;
  }
}
.summary-lit-list-card {
  .summary-lit-list-title {
    font-size: 12px;
    margin: 0px 10px;
    line-height: 1.5;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    position: relative;
    width: 100%;
    text-transform: capitalize;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 10px !important;
    }
  }
}
