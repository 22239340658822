.Search-page {
  
.inquiry-form-button {
  color: #252734 !important;
  background-color: #f7ee23 !important;
  border-color: #f7ee23 !important;
  width: 100%;
  justify-content: center;
}
.inquiry-form-button:hover {
  color: #252734 !important;
  background-color: #f7ee23 !important;
  border-color: #f7ee23 !important;
  justify-content: center;
}

}