.Library-page {
  .category-books-section {
    padding: 20px;
    margin-bottom: 20px;
  }
  .category-bottom-paginattion {
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 10px 10px 10px 10px;
    position: sticky;
    bottom: 0;
    z-index: 111;
    background: #f7f7f7;
  }
  .categogy-subscribe-now {
    a {
      color: #b42323;
    }
  }
  .Category-item {
    padding-bottom: 10px;
  }
  .category-search-form {
    padding-right: 40px;
    box-shadow: none !important;
  }
  .categogy-subscribe-now {
    padding-left: 15px;
    cursor: pointer;
    z-index: 111;
    height: 15px;
    display: flex;
    align-items: center;
  }
  .categogy-subscribe-nowL {
    color: #212945;
    letter-spacing: 0.2px;
    cursor: pointer;
    padding-left: 5px;
  }
  .CategoryLibIcon {
    font-size: 12px;
    color: #e09095;
    cursor: pointer;
  }
  .CategprySpinner {
    font-size: 8px;
    width: 15px;
    height: 15px;
  }
  .category-search-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 22px;
  }
  .btn-info {
    color: #081622 !important;
    background-color: #fcfcfc !important;
    border-color: #fcfcfc !important;
    border: 1px solid #dcdcdc !important;
    font-size: 17px;
  }
  .pagination-card {
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .pagination-item-first {
    margin-right: 5px;
  }
  .page-sec {
    .page-sec-card {
      display: flex;
      align-items: center;
      padding: 20px;

      .Banner-text-style {
        text-align: center;
        @media only screen and (min-width: 0px) and (max-width: 992px) {
          text-align: center;
        }
        h2 {
          font-weight: 600;
          font-size: 2rem;
        }
      }

      .Banner-image {
        border-top-left-radius: 0px;
        align-items: center;
        display: flex;
        justify-content: flex-end;
        @media only screen and (min-width: 0px) and (max-width: 992px) {
          display: none !important;
        }
        img {
          width: 100%;
          max-width: 100%;
          background-color: #000;
          padding: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .img-list-card {
    min-height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 10px;
    background: #fff0;
  }
  .img-list-img {
    height: 228px;
    object-fit: contain;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    border-radius: 10px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      height: 170px !important;
    }
  }
  .Category-text {
    width: 100%;
    padding: 0px 15px 0px 15px;
    h4 {
      font-size: 1rem;
      font-weight: 400;
      color: #363535;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      position: relative;
      width: 100%;
      margin-top: 0;
      margin-bottom: 0;
    }
    .authors {
      font-size: 0.9rem;
      font-weight: 400;
      color: #363535cc;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 0.5rem;
      margin-top: 5px;
    }
    .subtitle {
      font-size: 0.875rem;
      font-weight: 400;
      color: #363535cc;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-top: 5px;
    }
  }
  .category-books-heading {
    font-size: 24px;
    margin: 0;
    border-bottom: 1px solid #93d6e1;
    padding-bottom: 10px;
  }
  .category-view-more {
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 40px;
  }
  .category-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0px;
  }
}

.paginattion-search {
  display: flex;
  align-items: center;
}
.pagination-form-card {
  position: relative;
  width: 300px;
  max-width: 100%;
  margin-right: 10px;
}

.bouncing-loader {
  display: flex;
}

.bouncing-loader > div {
  width: 5px;
  height: 5px;
  margin: 0px 3px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}
@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-0px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
