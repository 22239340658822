.Account-page {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    .container {
      padding: 0 !important;
    }
    .updateButton {
      padding-top: 40px !important;
    }
    .section-card {
      padding-top: 40px !important;
    }
  }
  .profile-card {
    padding: 50px 15px 50px 15px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 0px 10px 20px 10px;
    }
  }
}
.tabel_cards {
  .table_search {
    height: 40px;
    min-width: 250px;
    border: 1px solid #e9e9e9 !important;
  }
  .table_search_card {
    position: relative;
    display: flex;
    align-items: center;
  }
  .table_search_icon {
    color: #22294582;
    font-size: 20px;
    position: absolute;
    top: 11px;
    right: 10px;
  }
  .tabelOverflow {
    overflow: auto;
    white-space: nowrap;
    width: 100%;
    position: relative;
  }
  .table td,
  .table th {
    vertical-align: middle !important;
    padding: 10px 17px 10px 17px !important;
    border: 1px solid #dee2e654;
    color: #5c5e5f;
    font-size: 14px;
    background: #f8f8f7;
  }
  .table {
    margin-bottom: 0 !important;
  }
  td:first-child {
    border-left: none !important;
  }
  td:last-child {
    border-right: none !important;
  }
  th:first-child {
    border-left: none !important;
    width: 10px;
  }
  th:nth-child(2) {
    width: 10px;
  }
  th:last-child {
    border-right: none !important;
  }
  .dFlex {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  .newFlex {
    display: flex;
    align-items: center;
  }
  .newFlex2 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border: 1px solid #eee;
  }
  .newFlexPagi {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: auto;
    width: 100%;
    white-space: nowrap;
    padding: 10px 0px 10px 20px;
    background: rgba(247, 248, 255, 0.5);
  }
  .table {
    text-align: left;
  }
}
.dlrdflInput {
  height: 50px !important;
}
.sticky-column {
  right: 0px;
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
}
.no-data-found {
  padding: 20px;
  width: 100%;
  text-align: center;
}
.account-heading {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    padding-top: 20px;
  }
}
