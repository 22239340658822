.Purchase-page-Success {
  .purchase-container {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 0 !important;
    }
  }
  .padding20 {
    padding: 20px;
  }
  height: 100vh;
}

.container {
  width: 900px;
}

label {
  margin-bottom: 3px;
}

.success-msg {
  color: #0f5132;
  text-align: center;
  margin-top: 20px;
}
.success-msg svg {
  font-size: 60px;
  border: 1px solid #0f5132;
  border-radius: 30px;
  padding: 10px;
}
.success-msg .title {
  font-size: 15px;
  margin-top: 10px;
}
.success-msg .title1 {
  font-size: 22px;
  margin-top: 10px;
}
.order-md-1 {
  padding: 0 !important;
}
