.Product-page {
  .page-title-heading {
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.5;
    color: #363535;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    position: relative;
    width: 100%;
    margin-bottom: 0.5rem;
    text-transform: capitalize;
  }

  .page-title-heading-b {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    color: #363535;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    position: relative;
    width: 100%;
    margin-bottom: 0.5rem;
    text-transform: capitalize;
  }

  .page-title-text {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-bottom: 1.25rem;
    color: #394649;
  }

  .img-list-card {
    min-height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 10px;
    background: #fff0;
  }
  .img-list-img {
    height: 228px;
    object-fit: contain;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    border-radius: 10px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      height: 170px !important;
    }
  }
  .Category-text {
    width: 100%;
    padding: 0px 15px 0px 15px;
    h4 {
      font-size: 1rem;
      font-weight: 400;
      color: #363535;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      position: relative;
      width: 100%;
      margin-top: 0;
      margin-bottom: 0;
    }
    .authors {
      font-size: 0.9rem;
      font-weight: 300;
      color: #363535cc;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 0.5rem;
      margin-top: 5px;
    }
    .subtitle {
      font-size: 0.875rem;
      font-weight: 400;
      color: #363535cc;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-top: 5px;
    }
  }

  .rhap_time {
    color: #fcfcfc !important;
    font-size: 12px !important;
  }
  .rhap_progress-container {
    padding: 0px 15px 0px 15px !important;
    margin: 0 !important;
  }
  .ProductDescrition_rtl {
    div {
      text-align: right;
    }
    p {
      text-align: right;
    }
    strong,
    em,
    span {
      text-align: right;
      padding-left: 10px;
    }
  }
  .ProductDescrition_ltr {
    div {
      text-align: left;
    }
    p {
      text-align: left;
    }
    strong,
    em,
    span {
      text-align: left;
      padding-right: 10px;
    }
  }
 
  .dFlex {
    display: flex;
    align-items: center;
  }
  .mr10 {
    margin-right: 10px;
  }
  .pr10 {
    padding-right: 10px;
  }
  .book-library_button {
    margin: 0;
    width: 88%;
    border-radius: 0;
    justify-content: center;
  }
  .book-library_button_card {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 111;
  }

  .font-class-ar {
    font-family: "GessTwoMedium, sans-serif";
  }
  .font-class-urdu {
    font-family: "AlQalam Shakir";
  }
  .font-class-default {
    font-family: "Poppins, sans-serif";
  }

  .HomePage_static_description {
    ul{
      padding-left: 15px !important;
    }
    div,
    p,
    em,
    span,
    ul,
    li {
      font-size: 1rem !important;
      font-weight: 300 !important;
      line-height: 1.5 !important;
      color: #212529 !important;
      background: #fff0 !important;
      text-transform: none;
      text-decoration: none;
      margin: 0 !important;
      padding-bottom: 10px !important;
      white-space: normal !important;
      text-align: start !important;
      padding: 0;
      font-style: normal;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    strong {
      font-size: 1rem !important;
      font-weight: 700 !important;
      line-height: 1.5 !important;
      color: #212529 !important;
      background: #fff0 !important;
      text-transform: none;
      text-decoration: none;
      margin: 0 !important;
      padding-bottom: 10px !important;
      white-space: normal !important;
      text-align: start !important;
      padding: 0;
      font-style: normal;
    }
  }

  .add-to-library-button {
    cursor: pointer;
  }
  .row-reverse-ar {
    .rhap_main {
      .rhap_controls-section {
        .rhap_main-controls {
          flex-direction: row-reverse !important;
        }

        .rhap_volume-container {
          flex-direction: row-reverse !important;
        }
      }
      .rhap_progress-section {
        flex-direction: row-reverse !important;
        .rhap_progress-container {
          .rhap_progress-bar {
            display: flex !important;
            flex-direction: row-reverse !important;
          }
        }
      }
    }
  }

  .summary-page-key {
    display: flex;
    padding-bottom: 10px;
    .margin {
      margin: 0 4px;
    }
  }

  .book-details-page-card {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 5px;
    }
    padding: 30px 30px 30px 30px;
  }
  .free-trial-button-book-page {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
  .product-page-icon {
    color: #969696;
    font-size: 1.2rem;
  }
  .rhap_header {
    margin-bottom: -10px !important;
    margin-right: 50px !important;
  }
  .rhap_container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    line-height: 1;
    font-family: inherit;
    width: 100%;
    text-align: center;
    background-color: #3e2145 !important;
    padding: 5px;
  }

  .book-img-plyr {
    .plyr {
      width: 100%;
      .voice-controls .plyr__time_voice + .plyr__time_voice:before {
        display: none;
      }
    }
    .book-lit-player {
      width: 100%;
    }
  }

  .book-img-list-card {
    min-height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 7px;
    padding: 0px;
    border-bottom-left-radius: 31px !important;
    border-bottom-right-radius: 31px !important;
  }
  .book-img-list-img {
    width: 100%;
    height: 360px;
    object-fit: contain;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    z-index: 2;
    border-radius: 7px;
  }

  .bp-book-image{
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    padding: 20px;
    border-radius: 10px;
  }

  .Book-card-item {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      max-width: 100%;
    }
   
    p {
      margin: 0;
      font-size: 14px;
    }
  }
  .Book-card-item1 {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      max-width: 100%;
    }
    padding-bottom: 20px;

    p {
      margin: 0;
    }
  }
  .Book-key-list-button {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding-top: 20px;
    }
  }
  .Product-row-reverse {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      flex-direction: column-reverse !important;
    }
  }
  .features {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      overflow: auto;
      white-space: nowrap;
    }

    .duration {
      padding-right: 20px;
      font-size: 15px;
      display: flex;
      align-items: center;
    }
    .keys {
      padding-right: 20px;
      display: flex;
      align-items: center;
      font-size: 15px;
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        font-size: 12px;
      }
    }
    .keys-ar {
      padding-left: 20px;
      display: flex;
      align-items: center;
      font-size: 15px;
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        font-size: 12px;
      }
    }
    .audio {
      font-size: 15px;
      display: flex;
      align-items: center;
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        font-size: 12px;
      }
    }
  }
  .Book-key-list {
    display: flex;
    align-items: center;
    padding: 20px 0px 20px 0px;
    justify-content: space-between;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 10px 0px 10px 0px !important;
      position: fixed !important;
      bottom: 0 !important;
      z-index: 111 !important;
      background: #fff !important;
      right: 10px !important;
      left: 10px !important;
      justify-content: center !important;
      overflow: auto;
      white-space: nowrap;
    }
  }
  .book-subtitle {
    padding: 20px 0px 20px 0px;
  }
  .book-title {
    font-size: 1.3rem;
    font-weight: 600;
  }
  .Book-details {
    padding: 30px 0px 20px 0px;
    border-bottom: 1px solid #21294517;
    margin-bottom: 25px;
    .Book-details-heading {
      font-weight: 600;
    }
  }
  .lit_button {
    background: #222945;
    border: 1px solid #222945;
    width: auto !important;
    justify-content: center;
    border-radius: 0;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      width: 100% !important;
    }
  }
  .book-button-width {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      width: 100% !important;
    }
  }
  .lit_button:hover {
    background: #222945de !important;
    border: 1px solid #222945de !important;
  }
  .lit_button:focus {
    background: #222945de !important;
    border: 1px solid #222945de !important;
  }
  .lit_button:active {
    background: #222945de !important;
    border: 1px solid #222945de !important;
  }
  .w100 {
    width: 100%;
  }
  .tac {
    text-align: center;
  }
  .jfc {
    justify-content: center;
  }

  .read-button:hover {
    .product-page-remove {
      display: block !important;
    }
    .product-page-saved {
      display: none !important;
    }
  }
  .product-page-remove {
    display: none;
  }

  .Book-summary-details-section {
    padding-top: 30px;
  }
  .Book-audio-player {
    border-radius: 100px !important;
    .rhap_additional-controls {
      display: none !important;
    }
    .rhap_volume-controls {
      display: none !important;
    }
    .rhap_main {
      display: flex !important;
      flex-direction: row-reverse !important;
      align-items: center !important;
      padding: 0px 10px 0px 10px;
    }
    .rhap_stacked .rhap_controls-section {
      margin-top: 0px !important;
      flex: none !important;
    }
    .rhap_container {
      border: none !important;
      box-shadow: none !important;
    }

    .Book-audio-player {
      border: none !important;
      box-shadow: none !important;
      padding: 40px 0px 40px 0px;
    }

    .rhap_play-pause-button {
      font-size: 50px !important;
      width: 50px !important;
      height: 50px !important;
      color: #fcfcfc;
    }
  }

  .Book-keys-list {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 7px !important;
    display: flex;
    align-items: center;
    span {
      font-size: 0.8rem;
      line-height: 1.2rem;
      margin-bottom: 0rem;
      color: #394649;
    }
    .ar {
      padding-left: 10px;
    }
    .en {
      padding-right: 10px;
    }
  }
  .Book-loginSignup {
    width: 100%;
  }
  .Book-loginSignup-form {
    border: 1px solid #eee;
    padding: 20px;
    border-radius: 6px;
    width: 470px;
    max-width: 100%;
    background: #fff;
  }
  .Book-loginSignup_rtl {
    padding-left: 40px;
  }
  .Book-loginSignup_ltr {
    padding-right: 40px;
  }
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #fff0 !important;
}

.ant-dropdown-menu-item:hover {
  background-color: #eee !important;
}
