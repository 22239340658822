.sfb {
  .Dashboard-CI-CRD {
    .Dashboard-CI {
      height: 246px;
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        height: 180px;
      }
    }
  }
}
