.Plans-page {
  .sp-title-p {
    padding: 40px 0px 0px 0px;
    margin-bottom: 0px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 10px 0px 20px 0px;
      margin-top: 5px !important;
      h4 {
        font-size: 15px !important;
      }
      text-align: center;
    }
  }
  .pricing-card-header {
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    color: #252734;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: start;
  }
  .purchase-card-list {
    padding-bottom: 10px;
    display: flex;
    font-size: 14px;
    width: 100%;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 12px !important;
    }
    svg {
      font-size: 28px;
    }
    span {
      margin: 0 10px;
    }
  }
  .price-offer-tag {
    position: absolute;
    top: 34%;
    right: 0px;
    font-size: 14px;
    background: #f7ee23;
    color: #252734;
    padding: 0px 10px;
    font-weight: 700;
  }
  .active-tag {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 12px;
    background: #45bdef;
    color: #f7f7f7;
    padding: 0px 10px;
    font-weight: 700;
  }
  .line-through {
    text-decoration: line-through;
    color: #b22020;
  }
  .price-size {
    font-size: 24px;
    font-weight: 700;
  }
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    .price-size {
      font-size: 18px;
    }
    .pricing-card-header {
      font-size: 18px;
    }
  }
  .subscription-options-list-row {
    @media only screen and (min-width: 568px) and (max-width: 1281px) {
      .subscription-options-list-card {
        max-width: 50% !important;
        flex: 0 0 100% !important;
        border-radius: 0 !important;
        margin-top: 15px !important;
      }
    }
    @media only screen and (min-width: 0px) and (max-width: 768px) {
      .subscription-options-list-card {
        max-width: 100% !important;
        flex: 0 0 100% !important;
        border-radius: 0 !important;
        margin-top: 15px !important;
      }
      .price-offer-tag {
        top: 0px !important;
      }
      .active-tag {
        top: 0px !important;
      }
    }
  }
  overflow: auto;
  width: 100%;
  margin: 0;
  padding: 0;
}
.MuiFormControlLabel-label {
  padding-top: 0px !important;
  line-height: 1.3 !important;
}
.css-ahj2mt-MuiTypography-root {
  width: 100%;
  padding-top: 9px;
}
.css-j204z7-MuiFormControlLabel-root {
  margin: 0 !important;
  align-items: flex-start !important;
  color: dimgrey !important;
  cursor: pointer;
}
.css-j204z7-MuiFormControlLabel-root:hover {
  color: #252734;
}
.MuiFormControlLabel-labelPlacementEnd {
  display: flex !important;
  align-items: center !important;
}
.pricing-trial-eligible {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    h5 {
      font-size: 14px !important;
    }
  }
  font-size: 14px;
  color: #252734;
}
.price-highlight {
  font-size: 12px;
  background: #252734;
  color: #fcfcfc;
  padding: 2px 10px 4px 10px;
  border-radius: 8px;
  top: -15px;
}
.section-card {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    display: flex !important;
    flex-wrap: wrap !important;
    flex-direction: column-reverse !important;
    .p0 {
      padding: 0px !important;
    }
  }
}

.purchase_modal {
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 500px;
    width: 100%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-content {
    border-radius: 12px !important;
  }
  .modal-content {
    border-radius: 10px !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border: none !important;
  }
  .modal-body {
    border-radius: 10px !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
}
.subscription-plan {
  svg {
    color: #02314bc2 !important;
    font-size: 1rem !important;
  }
  .MuiStepConnector-vertical {
    margin-left: 8px !important;
  }
}
.radio-steps-rtl {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0px;
  right: -7px;
}

.radio-steps-ltr {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0px;
  left: -7px;
}
 .price-steps-card{
  display: flex;
  margin: 0px 13px;
  padding: 0px 0px;
  position: relative;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    text-align: start;
  }
 }
 .price-steps-card{
 input[type='radio']{
  color: #2b2245 !important;
  background: #2b2245;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 100px;
 }
}
