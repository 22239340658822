.privacy-page {
  .terms-text {
    color: #212945c7;
  }
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    .pp_m {
      padding: 0 !important;
    }
  }
}
