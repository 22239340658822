.Purchase-page {
  .Header-singin-header {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      h5 {
        font-size: 14px !important;
      }
    }
  }
  .purchase-card-list {
    display: flex;
    align-items: baseline;
    padding-bottom: 5px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 12px;
    }
    svg {
      font-size: 12px;
      margin-right: 10px;
    }
  }
  .select-payment-method {
    padding: 10px;
    background: whitesmoke;
    border: 0.5px solid #02314b;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .select-payment-method-select {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .dFlex {
    display: flex;
    align-items: center;
  }
  .padding20 {
    padding: 20px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 10px !important;
    }
  }
  .p0 {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 0 !important;
    }
  }
  .padding-card-form {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 15px 0px 5px 0px !important;
      width: 100%;
      margin: 0;
      label {
        font-size: 12px !important;
      }
      button {
        font-size: 12px !important;
      }
    }
    padding: 10px 0px 0px 0px;
  }
  .mr10 {
    margin-right: 10px;
  }
  .mr0 {
    margin: 0;
  }
  .purchase-coupon-input {
    width: 100%;
    max-width: 100%;
    input {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      height: 40px;
    }
    input:focus {
      box-shadow: none !important;
    }
    button {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      height: 40px;
      white-space: nowrap;
      display: flex;
      justify-content: center;
    }
    button:hover {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      color: #f7ee23 !important;
      background-color: #252734 !important;
      border-color: #252734 !important;
      height: 40px;
    }
  }
  .purchase-complete-order-text {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 22px !important;
      text-align: center;
    }
  }
  .purchase-section {
    justify-content: center;
    @media only screen and (min-width: 0px) and (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }
  .purchase-form-card {
    padding: 0px;
    word-break: break-all;
    border-radius: 10px;
    @media only screen and (min-width: 0px) and (max-width: 768px) {
      margin-bottom: 20px;
      padding: 2px;
      h3 {
        font-size: 15px !important;
      }
    }
    .purchase-button {
      width: 100%;
    }
  }
  .purchase-info-card {
    background: #fcfcfc;
    border: 1px solid #00000017;
    word-break: break-all;
    border-radius: 10px;
    .purchase-info-sub-card {
      padding: 20px;
    }
    .purchase-infodiv {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        h4 {
          font-size: 16px !important;
        }
      }
    }
    .purchase-infodivl {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        display: block;
        h4 {
          font-size: 16px !important;
        }
      }
    }
  }
}
.margin-bottom {
  margin-bottom: 15px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.container {
  width: 900px;
}
label {
  margin-bottom: 3px;
}
.spinner-border {
  width: 1.3rem;
  height: 1.3rem;
  border-width: 0.1em;
}
.success-msg {
  color: #0f5132;
  text-align: center;
  margin-top: 20px;
}
.success-msg svg {
  font-size: 60px;
  border: 1px solid #0f5132;
  border-radius: 30px;
  padding: 10px;
}
.success-msg .title {
  font-size: 15px;
  margin-top: 10px;
}
.success-msg .title1 {
  font-size: 22px;
  margin-top: 10px;
}
.order-md-1 {
  padding: 0 !important;
}
.form-control-custom {
  height: 50px !important;
  padding: 11px !important;
  border: 1px solid #bfbfbf94 !important;
}
.form-control-custom:focus {
  box-shadow: none !important;
}
.coloredButton {
  background: #222945 !important;
  color: #fcfcfc !important;
  border: 1px solid #222945 !important;
  height: 50px;
}
.inquiry-form-button {
  color: #252734 !important;
  background-color: #f7ee23 !important;
  border-color: #f7ee23 !important;
  width: 100%;
  height: 46px;
}
.accept-text {
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    font-size: 11px;
  }
  font-size: 14px;
}
.csv-card {
  width: 50% !important;
  max-width: 50% !important;
  flex: 50% !important;
}
.expiry-date-card{
  width: 50% !important;
  max-width: 50% !important;
  flex: 50% !important;
}
button {
  text-transform: capitalize !important;
}
