.Search-page {
  position: relative;
  .add-to-library-button {
    cursor: pointer;
  }
  .search-page-subscription-button {
    text-transform: capitalize !important;
    font-weight: 400 !important;
  }
  .search-page-nav {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block !important;
    }
  }

  .search-filter-cards {
    white-space: nowrap !important;
    overflow: auto !important;
    a {
      border: 1px solid #d8dbdd;
      color: #000000b3;
      text-transform: capitalize;
      font-size: 1rem;
      font-weight: 400;
    }
  }
  .Banner-text-style {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 10px 0px 10px 0px;
      h3 {
        font-size: 18px !important;
      }
    }
  }
  .Category-item {
    width: 20%;
    padding: 10px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      width: 50% !important;
      padding: 7px !important;
      padding-bottom: 10px;
    }
    padding-bottom: 20px;
  }
  .category-books-section {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 0 !important;
    }
    padding: 7px 0px 20px 0px;
  }
  .category-bottom-paginattion {
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 10px 10px 10px 10px;
    position: sticky;
    bottom: 0;
    z-index: 111;
    background: #f7f7f7;
  }
  .category-search-form {
    padding-right: 40px;
    box-shadow: none !important;
  }
  .categogy-subscribe-nowL {
    color: #212945;
    letter-spacing: 0.2px;
    padding-left: 5px;
    cursor: pointer;
  }
  .search-text-on-hover {
    display: none;
  }
  .categogy-subscribe-nowL:hover {
    .search-text-on-hover {
      display: block !important;
    }
  }
  .CategoryLibIcon {
    font-size: 18px;
    margin-top: 1px;
    color: #21294554;
  }
  .CategprySpinner {
    font-size: 8px;
    width: 24px;
    height: 24px;
    margin-right: 5px;
    margin-top: 5px;
  }
  .categogy-subscribe-now {
    color: #212945;
    letter-spacing: 0.2px;
  }
  .category-search-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 22px;
  }
  .btn-info {
    color: #081622 !important;
    background-color: #fcfcfc !important;
    border: 1px solid #e0e0e0 !important;
    font-size: 15px;
    padding: 5px 20px 5px 20px;
    display: flex;
    align-items: center;
  }
  .pagination-card {
    display: flex;
    align-items: center;
    justify-content: right;
    ul {
      flex-wrap: nowrap;
      overflow: auto;
      white-space: normal;
    }
  }
  .pagination-item-lang {
    margin-right: 15px;
  }
  .pagination-item-ar {
    margin-left: 15px;
  }


  .img-list-card {
    min-height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 10px;
    background: #fff0;
  }
  .img-list-img {
    height: 228px;
    object-fit: contain;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    border-radius: 10px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      height: 170px !important;
    }
  }
  .Category-text {
    width: 100%;
    padding: 0px 15px 0px 15px;
    h4 {
      font-size: 1rem;
      font-weight: 400;
      color: #363535;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      position: relative;
      width: 100%;
      margin-top: 0;
      margin-bottom: 0;
    }
    .authors {
      font-size: 0.9rem;
      font-weight: 300;
      color: #363535cc;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 0.5rem;
      margin-top: 5px;
    }
    .subtitle {
      font-size: 0.875rem;
      font-weight: 400;
      color: #363535cc;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-top: 5px;
    }
  }
  .category-books-heading {
    font-size: 28px;
    margin: 0;
  }
  .category-view-more {
    padding: 40px;
    text-align: center;
  }
}
.category-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
}
.paginattion-search {
  display: flex;
  align-items: center;
}
.pagination-form-card {
  position: relative;
  width: 300px;
  max-width: 100%;
  margin-right: 10px;
}

.books-card-dropdown {
  float: right;
  svg {
    font-size: 17px;
    margin: 0;
    color: #696f75;
  }
}

.books-card-footer-size {
  font-size: 15px;
  color: #696f75;
}
.category-view-more {
  width: 100%;
}
