.inquiry-form-button {
  color: #252734 !important;
  background-color: #f7ee23 !important;
  border-color: #f7ee23 !important;
  width: 100%;
}
.inquiry-form-button:hover {
  color: #252734 !important;
  background-color: #f7ee23 !important;
  border-color: #f7ee23 !important;
}
.form-group {
  text-align: start;
}

.Login-card-separator-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 0;
  width: 400px;
  max-width: 100%;
}

.Login-card-separator {
  height: 1px;
  width: 45%;
  background-color: #b5b5ba;
  opacity: 0.3;
}

.Login-card-separator-or {
  width: 10%;
  text-align: center;
  font-size: 13px;
}

.Login-card-separator {
  height: 1px;
  width: 45%;
  background-color: #b5b5ba;
  opacity: 0.3;
}
.S9gUrf-YoZ4jf {
  width: 400px !important;
  @media only screen and (min-width: 0px) and (max-width: 768px) {
    width: 250px !important;
  }
}
.grecaptcha-badge { visibility: hidden; }
