.Header-page {
  background: #f5f5f5;
  top: 0;
  right: 0;
  left: 0;
  white-space: nowrap;
  z-index: 999;
  position: sticky;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    position: relative !important;
    white-space: normal !important;
  }
  .header-md {
    display: flex;
    align-items: center;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      flex-direction: row-reverse !important;
    }
  }

  .desktop-hide-trial {
    display: none;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block;
    }
  }

  .Header-side-menu2 {
    background: #fff;
    border: 1px solid #eee;
    overflow: auto;
    padding: 0 20px 20px;
    position: absolute;
    top: 50px;
    right: 0;
    left: 0;
    z-index: 9999;
  }

  .Header-menu-topnav:hover {
    color: #212945 !important;
  }
  .Header-waller-connect1:hover {
    color: #212945 !important;
  }
  .Header-side-menu-item a:hover {
    color: #212945 !important;
  }

  .table_search_card {
    position: relative;
    width: 100%;
    input::placeholder {
      font-size: 14px !important;
    }
  }
  .table_search_icon {
    color: #22294582;
    font-size: 20px;
    position: absolute;
    right: 2px;
    top: 1px;
  }
  .table_search_icon-ar {
    color: #22294582;
    font-size: 20px;
    position: absolute;
    left: 2px;
    top: 1px;
  }
  .book_search {
    border: 1px solid #2129453b !important;
    border-radius: 7px;
    height: 40px;
    max-width: 100%;
    min-width: 100%;
    padding-right: 40px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      border-radius: 0;
    }
  }
  .book_search:focus {
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid #21294594 !important;
  }

  .Header_searchIcon_rtl {
    position: absolute;
    left: 10px;
    z-index: 999;
    svg {
      font-size: 20px;
    }
  }

  .Header_searchIcon_ltr {
    position: absolute;
    right: 10px;
    z-index: 999;
    svg {
      font-size: 20px;
    }
  }

  .Header-sub-nav-list {
    align-items: center;
  }
  .nav-list-b {
    display: none;
  }
  .nav-list-h {
    display: flex;
  }

  .Header-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fcfcfc;
    border-bottom: 1px solid #eee;
    padding: 10px 0px 10px 0px;
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media only screen and (min-width: 0px) and (max-width: 992px) {
        display: block;
        overflow: auto;
        padding: 0 !important;
      }
    }
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block;
      padding: 0 !important;
    }
  }

  .Header-menu-icon {
    font-size: 16px;
    line-height: 0;
    box-shadow: none;
    background: #fff0;
    border: none;
    color: #444;
    padding-right: 50px;
    cursor: pointer;
    position: relative;
    padding: 10px;
    height: 64px;
    display: flex;
    align-items: center;
  }

  .header-logo-ar {
    padding-left: 20px;
  }
  .header-logo-en {
    padding-right: 20px;
  }

  .Header-menu-logoName {
    font-size: 20px;
    line-height: 0;
    box-shadow: none;
    background: #fff0;
    border: none;
    color: #222945;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 400;
    img {
      width: 70px;
    }
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      img {
        width: 38px !important;
      }
    }
    a {
      color: #222945;
      letter-spacing: 1px;
      font-weight: 600;
      font-size: 36px;
    }
  }

  .Header-menu-list {
    display: flex;
    align-items: center;
    width: 100%;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block;
      width: 100%;
      text-align: center;
    }
  }

  .Header-menu-list2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media only screen and (min-width: 0px) and (max-width: 992px) {
    .mobile-hide {
      display: none !important;
    }
  }

  // .Header-menu-account .Header-waller-connect1:last-child {
  //   padding: 7px 0px 7px 10px;
  // }

  .Header-waller-connect1 {
    padding: 0px 5px 0px 5px;
    text-transform: capitalize;
    border-radius: 2px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    margin-left: 0px;
    // position: relative;
    color: #000000bf;
    svg {
      color: #484949bf !important;
      font-weight: 400 !important;
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        width: 1.8rem !important;
        height: 1.8rem !important;
      }
    }
    button {
      color: #000000bf !important;
      font-weight: 400 !important;
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        min-height: auto !important;
      }
    }
    a {
      color: #000000bf !important;
      font-weight: 400 !important;
      display: flex;
      align-items: center;
      position: relative;
    }
    div {
      color: #000000bf !important;
      font-weight: 400 !important;
    }
    span {
      color: #000000bf !important;
      font-weight: 400 !important;
    }

    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 18px;
      padding: 5px 5px 5px 5px;
    }
  }

  .Header-button-connect {
    text-transform: capitalize;
    border-radius: 2px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    margin-left: 0px;
    // position: relative;
    color: #2d2d2d;
    small {
      color: #fcfcfc;
      display: flex;
      align-items: center;
      font-size: 14px;
    }
    a {
      display: flex;
      align-items: center;
      position: relative;
      color: #2d2d2d;
    }
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 18px;
      padding: 5px 5px 5px 5px;
    }
  }

  .Header-menu-account {
    display: flex;
    align-items: center;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      justify-content: center;
      padding-left: 0px;
    }
  }

  .Header-side-menu-item {
    color: #444;
    font-size: 1rem;
    display: block;
    width: 25%;
    padding: 0px 20px 0px 10px;
    margin-bottom: 15px;
    white-space: normal;
    a {
      color: #444;
    }
  }

  .Header-menu-icon:hover .Header-side-menu {
    display: block;
  }
  .Header-TMM {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0px 20px 0px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block;
    }
  }
}

a:hover {
  text-decoration: none !important;
}

.Header-Signin-dialogue {
  .Header-close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 111;
    cursor: pointer;
  }
  .modal-body {
    padding: 1.5rem !important;
  }
  input {
    border-radius: 4;
    height: 45px;
  }
}

.blockAccountModalf {
  display: block;
}
.hideAccountModalf {
  display: none;
}

.expand-style {
  margin-top: 2px;
  color: #3f3f3f;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 1.5rem;
}
.swiper-button-next,
.swiper-button-prev {
  z-index: 111;
  border-radius: 100px;
  padding: 0px;
  width: 5px;
  height: 0px;
  line-height: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -30px !important;
  margin-right: -30px !important;
}
.swiper {
  position: unset !important;
}

@font-face {
  font-family: "GessTwoMedium";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(../../assets/fonts/ge_ss_two_medium.otf) format("opentype");
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41,
    U+FB50-FDFF, U+FE80-FEFC;
}

@font-face {
  font-family: "AlQalam Shakir";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(../../assets/fonts/AlQalam_Shakir.otf) format("opentype");
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41,
    U+FB50-FDFF, U+FE80-FEFC;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: #212945;
}

.modal-content {
  background-color: whitesmoke !important;
}
.modal-content {
  border-radius: 10px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border: none !important;
}
.modal-body {
  border-radius: 10px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.header-search-media {
  position: relative;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    background: #f5f5f5;
    top: 0;
    right: 0;
    left: 0;
    white-space: nowrap;
    z-index: 111;
    position: sticky;
  }

  @media only screen and (min-width: 0px) and (max-width: 992px) {
    .desktop-hide {
      display: block !important;
    }
  }

  .desktop-hide {
    display: none;
  }
  .table_search_card {
    position: relative;
    width: 100%;
    input::placeholder {
      font-size: 14px !important;
    }
  }
  .table_search_icon {
    color: #22294582;
    font-size: 20px;
    position: absolute;
    right: 2px;
    top: 1px;
  }
  .table_search_icon-ar {
    color: #22294582;
    font-size: 20px;
    position: absolute;
    left: 2px;
    top: 1px;
  }
  .book_search {
    border: 1px solid #2129453b !important;
    border-radius: 4px;
    height: 40px;
    max-width: 100%;
    min-width: 100%;
    padding-right: 40px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      border-radius: 0;
    }
  }
  .book_search:focus {
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid #21294594 !important;
  }
}
input {
  box-shadow: none !important;
}
.header-category-list {
  .header-category-list-item {
    width: 50% !important;
    max-width: 100% !important;
    white-space: normal;
    text-align: start;
    margin-bottom: 3px;
  }
  ul {
    display: flex !important;
    flex-wrap: wrap !important;
    width: 575px !important;
    max-width: 100%;
  }
}

.login_modal {
  display: flex !important;
  align-items: center;
  z-index: 9999 !important;
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 500px;
    width: 100%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
  .modal-content {
    border-radius: 12px !important;
  }
  .modal-content {
    border-radius: 10px !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border: none !important;
  }
  .modal-body {
    border-radius: 10px !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
}
