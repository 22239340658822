.Alert__page {
  position: absolute;
  display: flex;
  align-items: center;
  right: 0;
  left: 0;
  height: 100%;
  justify-content: center;
  .Alert__message {
    width: 100%;
    justify-content: center;
  }

  .form-control {
    border: 1px solid #eee;
    height: 60px;
    box-shadow: none !important;
  }
  .card {
    padding: 45px;
    border: 1px solid #f0f0f0;
  }
  .Alert__Heading span {
    font-weight: 600;
    text-align: left;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 12px !important;
      white-space: nowrap;
    }
  }
  .Alert__Heading {
    text-align: left;
  }

  .Alert__Heading {
    margin-bottom: auto;
  }
  .Alert__Heading2 span {
    color: #3d2b93;
    font-weight: 800;
  }
  .card p {
    color: #3d2b93;
  }
  .dFlex {
    display: flex;
    align-items: center;
  }
  .EightPlus {
    background: #3d2c92;
    color: #fff;
    padding: 2px;
    border-radius: 4px;
    margin-right: 10px;
  }
  .EightPlus_Text {
    font-size: 12px;
    font-weight: 800;
    margin: 0;
    color: #3d2c92;
  }
  .Access-site {
    display: flex;
    align-items: center;
    margin-top: 20px;
    input {
      height: 50px;
    }
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block;
    }
  }
  .Access-site-button {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      width: 100%;
      margin: 0;
      margin-top: 10px;
    }
  }
  .danger {
    text-align: center;
    padding: 20px;
    width: 100%;
    color: #c11717;
  }
}

.Start-view-more {
  grid-gap: 0.5rem;
  align-content: left;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 0;
  justify-content: left;
  margin-bottom: 1rem;
  width: 100%;
  .Start_categories_card {
    align-items: center !important;
    display: flex !important;
    padding: 3px;
    width: auto;
  }
  .Start_categories {
    background-color: #21294514;
    border: 1px solid #2129451f;
    border-radius: 4px;
    color: #212945;
    cursor: pointer;
    padding: 10px 20px 10px 20px;
  }
}
// .lang-select-row {
//   cursor: pointer;
// }
// .lang-select-row:hover {
//   color: #02314bc2 !important;
//   text-decoration: underline;
// }
.lang-select-card {
  svg {
    font-size: 12px !important;
    color: #02314bc2 !important;
  }
  cursor: pointer !important;
  span {
    cursor: pointer !important;
  }
}
.lang-select-card:hover {
  .lang-select-row {
    color: #02314bc2 !important;
    text-decoration: underline;
  }
}

.select-ui-lng {
  .MuiStepConnector-vertical {
    display: none;
  }
}

.lng-list-login {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  padding: 7px 0px;
  cursor: pointer;
  justify-content: left !important;
  width: 100%;
}
 
