$light-border-color: #eee;
$dark-border-color: #eee;
$light-color: #f7f7f7;
$dark-color: #042330;
$dark-background: #35363a;
$dark-background: #f7f7f7;

.homepage-image-en:before {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    to left,
    #f7f7f7 0%,
    rgba(255, 255, 255, 0) 49%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 1;
}

.homepage-image-ar:before {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    to right,
    #f7f7f7 0%,
    rgba(255, 255, 255, 0) 49%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 1;
}

.Home-page {
  .table_search_card {
    position: relative;
    width: 100%;
  }

  .hp-lcontent {
    @media only screen and (min-width: 0px) and (max-width: 1180px) {
      .banner-text-heading {
        font-size: 1.1rem !important;
        text-align: center;
      }
      .banner-text {
        font-size: 14px !important;
        text-align: center;
      }
    }
  }

  .hp-title1 {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 1rem !important;
    }
  }
  .hp-title2 {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 10px !important;
    }
  }
  .audio-img-ff {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      height: auto !important;
    }
  }
  .react-player {
    position: relative !important;
    height: min-content !important;
    background-color: #000;
    // height: 375px !important;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      height: auto !important;
    }
    video {
      height: min-content;
      height: max-content;
      background-color: #000;
      object-fit: cover !important;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }
    .react-player__shadow {
      z-index: 111;
      background: #00000057 !important;
    }
  }
  .banner-container {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 0 !important;
    }
  }
  .banner-content-over {
    position: relative;
    right: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 1;
    top: 0;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      position: relative;
      padding-top: 20px;
    }
  }
  .banner-content-overv {
    position: relative;
    right: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 1;
    top: 0;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      position: relative;
      padding-top: 20px;
      display: none !important;
    }
  }

  .table_search_icon {
    color: #22294582;
    font-size: 20px;
    position: absolute;
    right: 2px;
    top: 3px;
  }
  .table_search_icon-ar {
    color: #22294582;
    font-size: 20px;
    position: absolute;
    left: 2px;
    top: 3px;
  }
  .book_search {
    border: 1px solid #2129453b !important;
    border-radius: 10px;
    height: 45px;
    max-width: 100%;
    min-width: 100%;
    padding-right: 40px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      border-radius: 0;
    }
  }
  .book_search:focus {
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid #21294594 !important;
  }

  .m-h-m-size {
    height: 245px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      height: 137px;
    }
  }
  .list-title {
    font-size: 1.5rem;
    line-height: 2rem !important;
    font-weight: 400;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 1.5rem;
    }
  }
  .card-title {
    font-size: 1.5rem;
    font-weight: 400 !important;
    line-height: 2rem !important;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 1.5rem;
    }
  }

  .h-s-carousel {
    display: block !important;
  }
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    .h-s-carousel {
      display: none !important;
    }
    .h-m-carousel {
      display: block !important;
      ul {
        li {
          height: auto !important;
        }
      }
      .list-content {
        h4 {
          font-size: 1rem;
          font-weight: 700;
          line-height: 1.5;
          color: #363535;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          position: relative;
          width: 100%;
          margin-top: 0;
          margin-bottom: 0;
        }
        .authors {
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.57;
          color: #6d787e;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-bottom: 0.5rem;
          margin-top: 5px;
        }
        .subtitle {
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.57;
          color: #042330;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-top: 5px;
        }
      }
    }
  }
  .recentlySection {
    background: #fcfcfc;
    padding: 20px 0px 30px 0px;
  }
  .banner-overlay {
    position: absolute;
    top: 0;
    height: 100%;
    right: 0;
    left: 0;
    z-index: 1;
    background: #00000099;
    border-radius: 4px;
  }
  .banner-text {
    font-size: 1rem;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block;
      font-size: 14px !important;
      text-align: center;
    }
  }
  .banner-text-heading {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      font-size: 1.1rem !important;
      text-align: center;
    }
  }
  .banner-image-lazy {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      height: 280px !important;
    }
  }
  .cat-img-list-item {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      width: 55% !important;
    }
  }
  .v-m-item {
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      width: 95% !important;
    }
  }
  .dashboard-category-section {
    padding: 20px;
  }
  .category-show-more {
    text-transform: capitalize;
  }
  .dashboard_categories {
    background-color: #f7f7f7;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #03314b2b;
    color: #363535;
  }
  .dashboard_categories:hover {
    background-color: #f8f8f8;
    border: 1px solid #03314ba8;
  }
  .dashboard-category-content {
    text-align: center;
    padding: 20px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      padding: 0;
    }
    h2 {
      @media only screen and (min-width: 0px) and (max-width: 992px) {
        font-size: 1.5rem;
        line-height: 2rem !important;
      }
    }
  }
  .Dash_categories_card {
    display: flex !important;
    align-items: center !important;
    padding-left: 3px;
    padding-right: 3px;
    padding-top: 3px;
    padding-bottom: 3px;
    width: auto;
  }
  .img-list-card {
    min-height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 10px;
    background: #fff0;
  }
  .img-list-img {
    height: 228px;
    object-fit: contain;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    border-radius: 7px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      height: 170px !important;
    }
  }
  .Category-text {
    width: 100%;
    padding: 0px 15px 0px 15px;
    h4 {
      font-size: 1rem;
      font-weight: 400;
      color: #363535;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      position: relative;
      width: 100%;
      margin-top: 0;
      margin-bottom: 0;
    }
    .authors {
      font-size: 0.875rem;
      font-weight: 400;
      color: #363535cc;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 0.5rem;
      margin-top: 5px;
    }
    .subtitle {
      font-size: 0.9rem;
      font-weight: 300;
      color: #363535cc;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-top: 5px;
    }
  }
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
  .Dashboard-CI-CRD {
    h6 {
      font-size: 15px;
      padding: 10px 0px 5px 0px;
      margin: 0;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 31px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    h5 {
      margin: 0;
      font-size: 18px;
    }
  }
  .Dashboard-CI {
    background-position: center;
    background-size: cover;
    width: 100%;
    background-repeat: no-repeat;
    height: 217px;
    object-fit: cover;
  }
  .Dashboard-view-more {
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    grid-gap: 0.5rem;
    gap: 0rem;
    margin-bottom: 1rem;
    display: inline-flex;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      display: block;
      .Dash_categories_card {
        display: block !important;
      }
    }
  }
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  display: none !important;
}
.Dashboard-information {
  padding: 20px 0px 20px 0px;
  .Dashboard-information-card {
    padding: 20px;
    text-align: center;
    background: #fcfcfc;
    border-radius: 10px;
    width: 100%;
    border: 1px solid #eee;
    img {
      width: 55px;
      margin-bottom: 10px;
      opacity: 0.6;
    }
    h4 {
      font-weight: 400;
    }
  }
  .Dashboard-information-sub {
    display: flex;
  }
}
.hover01 figure img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.3s ease-in-out;
}
.hover01 figure:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.column::after {
  content: "";
  clear: both;
  display: block;
}
figure {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.banner-pro-slider {
  .ui-video-seek-slider {
    margin-bottom: 18px;
    .track .main .inner-seek-block {
      height: 4px;
    }
    .track .main:before {
      height: 4px;
    }
    .track .main:hover .inner-seek-block {
      height: 5px;
    }
    .track .main:hover:before {
      height: 5px;
      .track .main .inner-seek-block {
        height: 5px;
      }
    }

    .thumb {
      .handler {
        background-color: tomato !important;
        display: none !important;
      }
    }
  }
}

.list-card {
  border: 1px solid #eee;
  display: block;
  height: 340px;
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    height: 280px;
  }
}

.book-library-icon {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
  svg {
    color: #000000ad;
  }
}

.list-card:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.Dashboard-books-card {
  .MuiImageListItem-item {
    overflow: unset !important;
  }
}
