.AccountHeader-page {

  .account-side-menu{
    border: 0.5px solid #02314b40;
    border-radius: 8px;
  }
  .active-account-header {
    color: #2773ff !important;
    font-weight: 600;
  }

  .AccountHeader-menu-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    background-color: #fcfcfc;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      white-space: nowrap;
      overflow: auto;
      justify-content: normal;
    }
    a {
      padding: 15px 20px 15px 20px;
      color: #444;
    }
    a:hover {
      padding: 10px 20px 10px 20px;
      border-radius: 2px;
      border: none;
      outline: none;
      text-decoration: none;
      color: #007bff;
    }
  }
  .AccountHeader-menu-item {
    width: 100%;
    color: #3e3d3d;
    font-size: 16px;
    @media only screen and (min-width: 0px) and (max-width: 992px) {
      margin-right: 10px;
    }
  }
}

.profile-page-mobile-menu-icon {
  display: none !important;
  @media only screen and (min-width: 0px) and (max-width: 992px) {
    display: block !important;
    position: absolute !important;
    right: 0px !important;
    padding: 0 !important;
    z-index: 111;
    button{
      margin: 0 !important;
    }
  }
}

.account-heading{
  font-weight: 400;
}
